import {
    SET_TOKEN,
    SET_USER,
    SET_USERS
} from "./actionTypes";

import { UserActions, UserState } from "./types";

const initialState: UserState = {
    token : null,
    user : undefined,
    users : []
};

const reducer = (state = initialState, action: UserActions) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token : action.payload
            }
        case SET_USER:
            return {
                ...state,
                user : action.payload
            }
        case SET_USERS:
            return {
                ...state,
                users : action.payload
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
