import React, { useEffect, useState } from 'react';
import App from '../app';
import Header from '../header';
import { connect } from 'react-redux';

import Login from '../login';
import { ToastContainer } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/user/actions';
import { RootState, AppDispatch } from '../../redux/config/store';
export const Loading = () => {
    return <div className="auth-loader">Authentication in progress...</div>
}

const AuthenticatedAppWrapper = ( props : any) => {

    const [ tokenFromLocalStorageChecked, setTokenFromLocalStorageChecked ] = useState<boolean>(false);

    useEffect(() => {
        // -- Check authorization
        if ( !tokenFromLocalStorageChecked ) {
            setTokenFromLocalStorageChecked(true);
            props.actions.getTokenFromLocalStorage();
        }
        if ( props.Token ) {
            props.actions.checkValidToken();
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.location, props.Token]);
    return (
        <React.Fragment>
            <Header />
            <ToastContainer />
            <main>
                <div className="container">
                    { props.Token ? <App /> : <Login /> }
                </div>

            </main>
        </React.Fragment>
    )
}


const mapStateToProps = ( state : RootState ) => {
    console.log(state.User);
    return {
        Token : state.User.token,
        User : state.User.user,
    
    }
}

const mapDispatchToProps = ( dispatch : AppDispatch ) => ({ actions: bindActionCreators({...actions }, dispatch) })

export default connect( mapStateToProps, mapDispatchToProps )( AuthenticatedAppWrapper );
