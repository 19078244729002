import { all, takeLatest } from 'redux-saga/effects';
import { SET_MESSAGE } from "./actionTypes";
import { SetMessage } from './types';
import { toast } from 'react-toastify';

export function* setMessage(action : SetMessage) {
    if ( action.payload.notification.type === 'success' ) {
        yield toast.success(action.payload.notification.message);
    }

    if ( action.payload.notification.type === 'error' ) {
        yield toast.error(action.payload.notification.message);
    }

    if ( action.payload.notification.type === 'warning' ) {
        yield toast.warn(action.payload.notification.message);
    }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* messageSaga() {
  yield all([
      takeLatest(SET_MESSAGE, setMessage),
  ]);
}
export default messageSaga;
