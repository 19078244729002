import {
    SET_CONTACT_CRUD_PROCESSING,
    SET_FETCH_CONTACTS_PROCESSING,
    SET_USER_CRUD_PROCESSING,
    SET_FETCH_USERS_PROCESSING,
} from "./actionTypes";

import { ProcessingActions, ProcessingState } from "./types";

const initialState: ProcessingState = {
    contact_crud : false,
    contact_fetch : false,
    user_crud : false,
    user_fetch : false,
};

const reducer = (state = initialState, action: ProcessingActions) => {
    switch (action.type) {
        case SET_CONTACT_CRUD_PROCESSING:
            return {
                ...state,
                contact_crud : action.payload
            }
        case SET_FETCH_CONTACTS_PROCESSING:
            return {
                ...state,
                contact_fetch : action.payload
            }
        case SET_USER_CRUD_PROCESSING:
            return {
                ...state,
                user_crud : action.payload
            }
        case SET_FETCH_USERS_PROCESSING:
            return {
                ...state,
                user_fetch : action.payload
            }
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
