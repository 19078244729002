import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthenticatedAppWrapper from './components/authenticatedAppWrapper';
import { HistoryRouter as Router } from "redux-first-history/rr6"

import { Provider } from 'react-redux';
import ReduxConfig  from "./redux/config/store";

import './assets/style/scss/style.scss';
import './@types/index.d.ts';
import 'react-table/react-table.css'
declare global {
    interface Window {
        apiUrl : string,
        storageURL : string,
        redirectUrl : string,
        token : string | undefined
    }
}
window.apiUrl = 'https://api.huisartshermans.nl/api';
window.redirectUrl = 'https://contact.huisartshermans.nl';
/*
if ( window.location.host === 'localhost:3000') {
    window.apiUrl = 'http://localhost:8000/api';
    window.redirectUrl = 'http://localhost:3000';
} else {
    window.apiUrl = 'https://api.contact.huishartshermans.displaynone.nl/api';
    window.redirectUrl = 'https://contact.huisartshermans.displaynone.nl';
}*/

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

root.render(
    <React.StrictMode>
        <Provider store={ReduxConfig.store}>
            <Router history={ReduxConfig.history}>
            <AuthenticatedAppWrapper />
            </Router>
        </Provider>
    </React.StrictMode>
);
