import axios from '../../AxiosInstance/axiosWrapper';
import { push } from 'react-router-redux';
import { all, call, put, takeLatest } from "redux-saga/effects";
import { setToken, setUsers, getUsers} from "./actions";
import {  AUTHENTICATE, SET_TOKEN, GET_TOKEN_FROM_LOCALSTORAGE, CHECK_VALID_TOKEN, GET_USERS, LOGOUT, UPDATE_USER, CREATE_USER, DELETE_USER } from "./actionTypes";
import { Authenticate, SetToken, CheckValidToken, GetUsers, Logout, UpdateUser, CreateUser, DeleteUser } from './types';
import { setMessage } from '../message/actions';
import { setUserFetchProcessing, setUserCrudProcessing } from '../processing/actions';

const authenticationRequest = ( userData : any ) => axios.post<string[]>(window.apiUrl + '/login/email', userData).then(response => { return response.data})
const checkValidTokenRequest = ( ) => axios.get<string[]>(window.apiUrl + '/isLoggedIn').then(response => { return response.data})
const getUsersApiRequest = (  ) => axios.get<User[]>(window.apiUrl + '/user/list').then(response => { return response.data})

const createUserApiRequest = ( contact : User ) => axios.post<any>(window.apiUrl + '/user/create', contact).then(response => { return response.data?.contact})
const updateUserApiRequest = (  contact : User ) => axios.post<any>(window.apiUrl + '/user/update', contact).then(response => { return response.data?.contact})
const deleteUserApiRequest = ( contact : User ) => axios.post<any>(window.apiUrl + '/user/delete', contact).then(response => { return response.data })

function* deleteUser( action : DeleteUser) {
    try {
        yield put(setUserCrudProcessing(true));
        yield call(deleteUserApiRequest, action.payload );
        yield put(getUsers(false));
        yield put(setUserCrudProcessing(false));
        yield put(push('/user'));
        yield put(
            setMessage({
                notification : {
                    message : 'Contact verwijderd',
                    type : 'success'
                }
            })
        );

      }catch(e : any) {
          yield put(setUserCrudProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Gebruiker verwijderen mislukt: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* createUser( action : CreateUser) {
    try {
        yield put(setUserCrudProcessing(true));
        yield call(createUserApiRequest, action.payload);
        yield put(getUsers(false));
        yield put(setUserCrudProcessing(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Gebruiker aangemaakt',
                    type : 'success'
                }
            })
        );

      }catch(e : any) {
          yield put(setUserCrudProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Gebruiker aanmaken mislukt: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* updateUser( action : UpdateUser) {
    try {
        yield put(setUserCrudProcessing(true));
        yield call(updateUserApiRequest, action.payload);
        yield put(getUsers());
        yield put(setUserCrudProcessing(false));
        yield put(
            setMessage({
                notification : {
                    message : 'Gebruiker geupdated',
                    type : 'success'
                }
            })
        );

      }catch(e : any) {
          yield put(setUserCrudProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Gebruiker updaten mislukt: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* getAllUsers( action : GetUsers) {
    try {
        if ( action.payload ) {
            yield put(setUserFetchProcessing(true));
        }
        const users : User[] = yield call(getUsersApiRequest);
        if ( users ) {
            yield put(setUsers(users));
        }

        yield put(setUserFetchProcessing(false));

      }catch(e : any) {
          yield put(setUserFetchProcessing(false));
          yield put(
              setMessage({
                  notification : {
                      message : 'Could not fetch users: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}

function* authenticate( action : Authenticate) {
    try {
        const authenticationResponse : AuthenticationResponse = yield call(authenticationRequest, action.payload);
        yield put(setToken(authenticationResponse.token))
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Er ging iets mis tijdens het inloggen',
                      type : 'error'
                  }
              })
          );
      }
}


function* setTokenToLocalStorage( action : SetToken) {
    try {
        if ( action.payload ) {
            localStorage.setItem('token', action.payload)
        } else {
            localStorage.setItem('token', '')
        }
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Could add token to localstorage: ' + e.message,
                      type : 'error'
                  }
              })
          );
      }
}


function* checkValidToken( action : CheckValidToken) {

    try {
        const result : User[] = yield call(checkValidTokenRequest);

      }catch(e : any) {
          yield put(setToken(null));
          yield put(
              setMessage({
                  notification : {
                      message : 'Je bent uitgelogd',
                      type : 'error'
                  }
              })
          );
      }
}

function* getTokenFromLocalStorage( action : SetToken) {
    try {
        if ( localStorage.getItem('token') ) {
            const token = localStorage.getItem('token');
            if ( token ){
                yield put(setToken(token))
            }
        }
      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Er ging iets mis tijdens het inloggen',
                      type : 'error'
                  }
              })
          );
      }
}


function* logoutUser( action : Logout) {
    try {
        if ( localStorage.getItem('token')  ) {
            const token = localStorage.getItem('token');
            yield put(setToken(null))
            yield put(push('/login'));

            yield put(
                setMessage({
                    notification : {
                        message : 'Je bent uitgelogd',
                        type : 'success'
                    }
                })
            );
        }


      }catch(e : any) {
          yield put(
              setMessage({
                  notification : {
                      message : 'Er ging iets mis tijdens het uitloggen',
                      type : 'error'
                  }
              })
          );
      }
}


function* userSaga() {
  yield all([
      takeLatest(AUTHENTICATE, authenticate),
      takeLatest(DELETE_USER, deleteUser),
      takeLatest(UPDATE_USER, updateUser),
      takeLatest(CREATE_USER, createUser),
      takeLatest(GET_USERS, getAllUsers),
      takeLatest(SET_TOKEN, setTokenToLocalStorage),
      takeLatest(LOGOUT, logoutUser),
      takeLatest(CHECK_VALID_TOKEN, checkValidToken),
      takeLatest(GET_TOKEN_FROM_LOCALSTORAGE, getTokenFromLocalStorage)
  ]);
}

export default userSaga;
